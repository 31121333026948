var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "i-sticky",
        [
          _c("i-header", {
            staticClass: "groupheader",
            attrs: { title: "参团基本信息", theme: "light", showBack: true },
            on: { "back-event": _vm.eventDetailGoBack },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form_box" },
        [
          _vm.template && _vm.template[0]
            ? _c(
                "i-form",
                { ref: "form", on: { submit: _vm.onSubmit } },
                [
                  _vm._l(_vm.template[0], function (item) {
                    return _c("iFormItem", {
                      key: item.type + item.controlName,
                      ref: item.controlName,
                      refInFor: true,
                      attrs: {
                        formData: _vm.formData,
                        formTemplate: _vm.template,
                        "model-val": _vm.formData[item.controlName],
                        "form-item": item,
                      },
                      on: { callBack: _vm.formCallBack },
                    })
                  }),
                  !_vm.$route.query.readOnly
                    ? _c(
                        "i-button",
                        {
                          attrs: {
                            loading: _vm.loading,
                            disabled: _vm.loading,
                            "loading-text": "参团",
                            block: "",
                            round: "",
                            type: "primary",
                            size: "normal",
                          },
                        },
                        [_vm._v(" 参团 ")]
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }